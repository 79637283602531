// Contact Icons
import { ReactComponent as Figma} from '../Resume/assets/icons/design_skills/figma.svg';
// GFP: Global Fleet Platform
import { ReactComponent as Git} from '../Resume/assets/icons/front_end_skills/git.svg';
import { ReactComponent as JavaScript} from '../Resume/assets/icons/front_end_skills/javaScript.svg';
import { ReactComponent as ReactJS} from '../Resume/assets/icons/front_end_skills/react_js.svg';
import { ReactComponent as Storybook} from '../Resume/assets/icons/front_end_skills/storybook.svg';
import { ReactComponent as Redux} from '../Resume/assets/icons/front_end_skills/redux.svg';
import { ReactComponent as Saas} from '../Resume/assets/icons/front_end_skills/saas.svg';
import { ReactComponent as Html5} from '../Resume/assets/icons/front_end_skills/HTML5.svg';
import { ReactComponent as Bootstrap} from '../Resume/assets/icons/front_end_skills/bootstrap.svg';

export const useCase = [
  {
    icon: <Figma />,
    name: `Figma`,
    gap: `7px`,
  },
  {
    icon: '',
    name: `Product Design`,
    gap: `7px`,
  },
  {
    icon: '',
    name: `UX Research`,
    gap: `7px`,
  },
  {
    icon: '',
    name: `Wireframes`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Interactive Prototype`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Usability`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Data Analytics`,
    gap: `0`,
  },
  {
    icon: '',
    name: `A/B Testing`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Critical Thinking`,
    gap: `0`,
  },
  {
    icon: '',
    name: `UI QA`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Design System`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Interaction`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Agile/Scrum`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Jira`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Mobile Design (IOS/Android)`,
    gap: `0`,
  },
]

export const gfp = [
  {
    icon: '',
    name: `Product Design`,
    gap: `7px`,
  },
  {
    icon: '',
    name: `Design System Components`,
    gap: `0`,
  },
  {
    icon: <Figma />,
    name: `Figma`,
    gap: `7px`,
  },
  {
    icon: '',
    name: `UX Research`,
    gap: `7px`,
  },
  {
    icon: '',
    name: `Interaction`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Usability`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Data Analytics`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Agile/Scrum`,
    gap: `0`,
  },
  {
    icon: <Git />,
    name: `Git`,
    gap: `7px`,
  },
  {
    icon: <JavaScript />,
    name: `JavaScript`,
    gap: `7px`,
  },
  {
    icon: <ReactJS />,
    name: `React JS`,
    gap: `7px`,
  },
  {
    icon: <Storybook />,
    name: `Storybook`,
    gap: `7px`,
  },
  {
    icon: <Redux />,
    name: `Redux`,
    gap: `7px`,
  },
  {
    icon: <Saas />,
    name: `SCSS`,
    gap: `7px`,
  },
  {
    icon: <Html5 />,
    name: `HTML5`,
    gap: `7px`,
  },
  {
    icon: <Bootstrap />,
    name: `Bootstrap`,
    gap: `7px`,
  },
  {
    icon: '',
    name: `Leadership`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Global`,
    gap: `0`,
  },
]

export const dsc = [
  {
    icon: '',
    name: `Management`,
    gap: `7px`,
  },
  {
    icon: '',
    name: `Design System`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Components`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Token & Aliases`,
    gap: `0`,
  },
  {
    icon: '',
    name: `Global Variables`,
    gap: `0`,
  },
  {
    icon: <Figma />,
    name: `Figma`,
    gap: `7px`,
  },
  {
    icon: <Git />,
    name: `Git, CI/CD`,
    gap: `7px`,
  },
  {
    icon: <JavaScript />,
    name: `JavaScript`,
    gap: `7px`,
  },
  {
    icon: <ReactJS />,
    name: `React JS`,
    gap: `7px`,
  },
  {
    icon: <Saas />,
    name: `SCSS`,
    gap: `7px`,
  },
  {
    icon: <Html5 />,
    name: `HTML5`,
    gap: `7px`,
  },
  {
    icon: <Bootstrap />,
    name: `Bootstrap`,
    gap: `7px`,
  },
  {
    icon: <Storybook />,
    name: `Storybook`,
    gap: `7px`,
  },
]
